.button {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    width: auto;
    height: 30px;
    padding: 0 20px;
    color: white;
    border: 0;
    outline: none;
    border-radius: 15px;
    margin-left: 15px;
    background-color: rgb(0, 122, 255);
    cursor: pointer;
    box-shadow: 0 5px 20px rgba(0, 122, 255, 0.5);
    transition: box-shadow 0.5s;
}

.button:hover {
    box-shadow: 0 5px 40px rgba(0, 122, 255, 0.7);
}
